<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <template slot="footer">
        <a-button
          v-if="config.type === 'edit' || config.type === 'handle'"
          @click="handleCancel"
        >
          取消
        </a-button>
        <a-button v-if="config.type === 'handle'" @click="openInnerModel">
          不通过
        </a-button>
        <a-button v-if="config.type === 'look'" @click="handleCancel">
          关闭
        </a-button>
        <a-button
          v-if="config.type === 'handle'"
          key="submit"
          type="primary"
          @click="handleOk"
        >
          通过
        </a-button>
        <a-button
          v-if="config.type === 'edit'"
          key="submit"
          type="primary"
          @click="handleOk"
        >
          保存
        </a-button>
      </template>

      <a-spin :spinning="spinning">
        <div>
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            class="formContent"
          >
            <a-form-model-item label="真实姓名" prop="applyName">
              <a-input
                :maxLength="20"
                class="formInputWidth"
                :disabled="config.status"
                v-model="form.applyName"
                placeholder="请输入"
              />
            </a-form-model-item>
            <a-form-model-item label="身份证号码" prop="idCard">
              <a-input
                class="formInputWidth"
                :disabled="config.status"
                v-model="form.idCard"
                placeholder="请输入"
              />
            </a-form-model-item>

            <a-form-model-item label="身份证" prop="idCardImg">
              <div class="idCard_contain">
                <div class="idCard">
                  <UploadTag
                    :config="uploadIdcardConfig"
                    @getImageUrl="getIdcardUrl"
                  ></UploadTag>
                  <div class="idText">身份证人像面</div>
                </div>
                <div class="idCard">
                  <UploadTag
                    :config="uploadIdcardBgConfig"
                    @getImageUrl="getIdcardBgUrl"
                  ></UploadTag>
                  <div class="idText">身份证国徽面</div>
                </div>
                <div class="idCard">
                  <UploadTag
                    :config="uploadIdcardPSConfig"
                    @getImageUrl="getIdcardAllUrl"
                  ></UploadTag>
                  <div class="idText_three">
                    手持身份证正面（确保身份证清晰）
                  </div>
                </div>
              </div>
            </a-form-model-item>

            <a-form-model-item
              label="拟上架应用信息"
              prop="planShelfApplication"
            >
              <div class="upload_contain">
                <UploadTag
                  :config="planShelfConfig"
                  @getImageUrl="getPlanShelfUrl"
                ></UploadTag>
                <div v-if="config.type !== 'look'" class="notice_text_new">
                  支持格式： pdf、pptx、ppt ，单个文件不能超过20MB。<br />
                  简要介绍产品背景、主要功能、进度规划、运营方向等
                </div>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
      <InnerModel
        v-if="modelConfig.show"
        @handleOk="handleInnerOk"
        @handleCancel="handleInnerCancel"
        :config="modelConfig"
      ></InnerModel>
    </a-modal>
  </div>
</template>

<script>
import { personDeveloperApi } from "@/api/personDeveloper.js";
import UploadTag from "../../components/uploadTag.vue";
import InnerModel from "./innerModel.vue";

export default {
  components: {
    UploadTag,
    InnerModel,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
          status: false,
        };
      },
    },
  },
  data() {
    //校验身份证
    let validatorIdcard = (rule, value, callback) => {
      if (this.idCardFront && this.idCardBackend && this.idCardHold) {
        callback();
      } else {
        callback(new Error("身份证照片信息不完整！"));
      }
    };
    return {
      spinning: false,
      confirmLoading: false,
      butloading: false,
      defaultFileList: [],
      detailId: null, //详情Id
      idCardFront: "", //身份证证明
      idCardBackend: "", //身份证背面
      idCardHold: "", //身份证手持的url
      // authorizeProve: "", //授权证明
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      modelConfig: {
        id: "",
        show: false,
        record: null,
        width: "600px",
      },
      planShelfConfig: {
        show: true,
        barrelType: "secret",
        uploadType: "default",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        fileList: [], //文件列表
        limitType: ["application/vnd.ms-powerpoint", "application/pdf", "pptx"],
      },
      uploadIdcardConfig: {
        barrelType: "secret",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        idCard: true,
        cardType: 1,
        fileList: [], //文件列表
      },

      uploadIdcardBgConfig: {
        barrelType: "secret",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        idCard: true,
        cardType: 2,
        fileList: [], //文件列表
      },
      uploadIdcardPSConfig: {
        barrelType: "secret",
        uploadType: "picture",
        showPreview: true, //是否展示预览图标
        showRemove: true, //是否展示删除图标
        size: 10, //限制文件大小
        limitLength: 1, //限制上传文件数量
        limitControl: true, //是否限制照片墙数量
        idCard: true,
        cardType: 3,
        fileList: [], //文件列表
      },
      form: {
        applyName: "",
        idCard: "",
        idCardImg: "",
        planShelfApplication: "",
      },

      rules: {
        applyName: [
          {
            required: true,
            message: "真实姓名不能为空",
            trigger: "blur",
            //whitespace: true,
          },
        ],
        idCard: [
          {
            required: true,
            message: "身份证号不能为空",
            trigger: "blur",
            //whitespace: true,
          },
        ],
        idCardImg: [
          {
            required: true,
            validator: validatorIdcard,
            trigger: "blur",
          },
        ],
        planShelfApplication: [
          {
            required: false,
            message: "拟上架应用信息不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.initModal();
  },
  watch: {},
  methods: {
    handleInnerOk() {
      this.modelConfig.show = false;
      this.$emit("handleOk");
    },

    initModal() {
      if (this.config.type === "look") {
        this.uploadIdcardConfig.showRemove = false;
        this.uploadIdcardBgConfig.showRemove = false;
        this.uploadIdcardPSConfig.showRemove = false;
      } else {
        this.uploadIdcardConfig.showRemove = true;
        this.uploadIdcardBgConfig.showRemove = true;
        this.uploadIdcardPSConfig.showRemove = true;
      }
      this.getReviewData();
    },
    //第二个弹框关闭
    handleInnerCancel() {
      this.modelConfig.show = false;
    },
    //第二个弹框打开
    openInnerModel() {
      this.modelConfig.record = {
        id: this.detailId,
        type: 3, //1.企业账户，2.企业开发者 3.个人开发者
        inspectType: 1, //1.审核，2修改
        inspectId: this.config.id,
        idCardFront: this.idCardFront,
        idCardBackend: this.idCardBackend,
        idCardHold: this.idCardHold,
        ...this.form,
        status: 3, //2审核通过 ，3审核失败
        rowVersion: this.config.record.rowVersion,
      };
      this.modelConfig.show = true;
    },
    //获取弹框审核详细数据
    getReviewData() {
      this.spinning = true;
      let id = this.config.id;
      personDeveloperApi.getReviewData(id).then((res) => {
        if (res.success) {
          this.detailId = res.data.id;
          this.form.applyName = res.data.applyName;
          this.form.idCard = res.data.idCard;
          this.idCardFront = res.data.idCardFront;
          this.uploadIdcardConfig.fileList.push({
            name: res.data.idCardFront.substring(res.data.idCardFront),
            uid: res.data.idCardFront,
            status: "done",
            url: res.data.idCardFront,
          });
          this.idCardBackend = res.data.idCardBackend;
          this.uploadIdcardBgConfig.fileList.push({
            name: res.data.idCardBackend.substring(res.data.idCardBackend),
            uid: res.data.idCardBackend,
            status: "done",
            url: res.data.idCardBackend,
          });
          this.idCardHold = res.data.idCardHold;
          this.uploadIdcardPSConfig.fileList.push({
            name: res.data.idCardHold.substring(res.data.idCardHold),
            uid: res.data.idCardHold,
            status: "done",
            url: res.data.idCardHold,
          });
          if (res.data.planShelfApplication) {
            this.planShelfConfig.fileList.push({
              name: res.data.planShelfApplication.substring(
                res.data.planShelfApplication
              ),
              uid: res.data.planShelfApplication,
              status: "done",
              url: res.data.planShelfApplication,
            });
          }

          this.form.planShelfApplication = res.data.planShelfApplication;

          this.spinning = false;
        } else {
          this.$message.error(res.errorMsg);
          this.spinning = false;
        }
      });
    },

    //审核通过
    passReview() {
      this.confirmLoading = true;
      let params = {
        id: this.detailId,
        type: 3, //1.企业账户，2.企业开发者 3.个人开发者
        inspectType: 1, //1.审核，2修改
        inspectId: this.config.id,
        idCardFront: this.idCardFront,
        idCardBackend: this.idCardBackend,
        idCardHold: this.idCardHold,
        ...this.form,
        status: 2, //2审核通过 ，3审核失败
        rowVersion: this.config.record.rowVersion,
      };
      personDeveloperApi.PassReview(params).then((res) => {
        if (res.success) {
          this.$emit("handleOk");
          this.confirmLoading = false;
        } else {
          this.$message.error(res.errorMsg);
          this.confirmLoading = false;
        }
      });
    },
    //修改成功
    editReview() {
      this.confirmLoading = true;

      let params = {
        id: this.detailId,
        type: 3, //1.企业账户，2.企业开发者 3.个人开发者
        inspectType: 2, //1.审核，2修改
        inspectId: this.config.id,
        ...this.form,
        idCardHold: this.idCardHold,
        idCardFront: this.idCardFront,
        idCardBackend: this.idCardBackend,
        rowVersion: this.config.record.rowVersion,
      };
      personDeveloperApi.PassReview(params).then((res) => {
        if (res.success) {
          this.$emit("handleOk");
          this.confirmLoading = false;
        } else {
          this.$message.error(res.errorMsg);
          this.confirmLoading = false;
        }
      });
    },
    getPlanShelfUrl(val, fileList) {
      this.form.planShelfApplication = val;
      this.planShelfConfig.fileList = fileList;
    },
    //获取人像持照片地址
    getIdcardAllUrl(val, fileList) {
      this.idCardHold = val;
      this.uploadIdcardPSConfig.fileList = fileList;
    },
    //获取身份证正面照片地址
    getIdcardUrl(val, fileList) {
      this.idCardFront = val;
      this.uploadIdcardConfig.fileList = fileList;
    },
    //获取身份证背面地址
    getIdcardBgUrl(val, fileList) {
      this.idCardBackend = val;
      this.uploadIdcardBgConfig.fileList = fileList;
    },

    handleCancel() {
      this.$emit("handleCancel");
    },

    handleOk() {
      if (this.config.type === "handle") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.passReview();
            this.$emit("innerHandleCancel");
          } else {
            return false;
          }
        });
      } else if (this.config.type === "edit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.editReview();
            this.$emit("innerHandleCancel");
          } else {
            return false;
          }
        });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formContent {
  width: 100%;
}
.formInputWidth {
  width: 500px;
}
.upload_contain {
  position: relative;
}

.notice_text {
  color: rgba(0, 0, 0, 0.39);
  font-size: 12px;
}
.notice_text_new {
  color: rgba(0, 0, 0, 0.39);
  font-size: 12px;
  position: absolute;
  width: 400px;
  height: 20px;
  line-height: 20px;
  top: 0;
  left: 120px;
  margin-top: 2px;
}

.add_positon {
  position: relative;
}
.modal_content {
  display: flex;
}
.modal_left {
  min-width: 120px;
  text-align: right;
}
.modal_right {
  padding-bottom: 10px;
  flex-grow: 1;
}
.modal_text {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding-top: 4px;
  .modal_logo {
    margin-left: 10px;
    margin-top: -6px;
  }
}
.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 30px;
  font-weight: 200;
  height: 60px;
}

.header_contain {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  padding-top: 20px;
}
.idCard_contain {
  display: flex;
  min-width: 200px;
  height: 220px;
}
.idText {
  position: absolute;
  min-width: 400px;
  bottom: 20px;
  left: 80px;
}
.idText_three {
  position: absolute;
  min-width: 400px;
  bottom: 20px;
  left: 10px;
}
.idCard {
  position: relative;
  width: 33%;
}
.idCard /deep/.ant-upload-list-picture-card .ant-upload-list-item {
  width: 240px;
  height: 160px;
}
.idCard /deep/ .ant-upload-list-picture-card-container {
  width: 240px;
  height: 140px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
/deep/.ant-table-header {
  min-width: 0px !important;
}
</style>